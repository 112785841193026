import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckUnsavedChanges = () => {
  const { t } = useTranslation();
  const message = t("warningMessageUnsaved");
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    window.onbeforeunload = isDirty ? () => isDirty && !!message : null;

    return () => {
      window.removeEventListener("beforeunload", () => {});
    };
  }, [isDirty, message]);

  const routerPrompt = <Prompt when={isDirty} message={message} />;
  const onDirty = () => setIsDirty(true);
  const onPristine = () => setIsDirty(false);

  return { routerPrompt, onDirty, onPristine };
};

export default CheckUnsavedChanges;
