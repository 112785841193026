import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
`;

export const List = styled.div`
  display: "flex";
  width: 100%;
  background-color: #edf3ea;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const EditButton = styled.button`
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: ${(props) => (props.primary ? "blue" : "green")};
  color: white;
  cursor: pointer;
  margin-right: 20px;
`;

export const ActionButton = styled.button`
  display: flex;
  margin-right: 20px;
  svg {
    font-size: 22px;
  }
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
`;
