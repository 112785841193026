import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UserService from "../services/user.service";
import { Form } from "@formio/react";
import { Container } from "../common/styles/PageContainer";
import { Container as LogoContainer } from "../common/styles/BuilderConfig";
import { builderTranslations } from "../common/translations/builderTranslations";
import { useTranslation } from "react-i18next";
import { FooterContainer } from "../common/styles/Footer";
import { Modal } from "react-bootstrap";
import { Buffer } from "buffer";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


import {
    Card,
    Circle,
    Exclamation,
    Message,
} from "../common/styles/SuccessPageStyles";
import { useHistory } from "react-router-dom";

const ApplicantForm = () => {
    const { i18n } = useTranslation();
    const { id } = useParams();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [formLogo, setFormLogo] = useState(null);
    const [logoInactive, setLogoInactive] = useState(null);
    const [logoPosition, setLogoPosition] = useState("");
    const [logoAllign, setLogoAllign] = useState("");
    const [logoWidth, setLogoWidth] = useState("");
    const [formWidth, setFormWidth] = useState("");
    const [backgroundColor, setBackgroundColor] = useState("");
    const [formContainerColor, setFormContainerColor] = useState("#ffffff");
    const [formPaginationColor, setFormPaginationColor] = useState("#ffffff");
    const [formPaginationTextColor, setFormPaginationTextColor] =
        useState("#000000");

    const [formBarColor, setFormBarColor] = useState("#ffffff");
    const [formBarTextColor, setFormBarTextColor] = useState("#000000");

    const [topPadding, setTopPadding] = useState("0px");
    const [errorMessage, setErrorMessage] = useState(null);
    const [formSchema, setFormSchema] = useState(null);
    const [reqLogin, setReqLogin] = useState(null);
    const [licenceKundenname, setLicenceKundenname] = useState("");
    const [applicantEmail, setApplicantEmail] = useState(null);
    const [submission, setSubmission] = useState(null);
    const [customerMailbox, setCustomerMailbox] = useState("");
    const [applicationType, setApplicationType] = useState("");
    const [foundationNumber, setFoundationNumber] = useState("");

    const B4IT_HOMEPAGE = process.env.REACT_APP_B4IT_HOMEPAGE;
    const B4IT_IMPRESSUM = process.env.REACT_APP_B4IT_IMPRESSUM;
    const B4IT_DATA_SECURITY = process.env.REACT_APP_B4IT_DATA_SECURITY;

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const history = useHistory();
    const [inactiveFormMessage, setInactiveFormMessage] = useState();
    const [submissionSuccessMessage, setSubmissionSuccessMessage] = useState();
    const [emailSubject, setEmailSubject] = useState();

    const predefined_component_keys = [
        "scOrgname",
        "scOrgstreet",
        "scOrghouseno",
        "scOrgzipcode",
        "scOrgcity",
        "scSalutation",
        "scFirstname",
        "scLastname",
        "scTitle",
        "scEmail",
        "scPhone",
        "scEmailOrg",
        "scPhoneOrg",
        "scIban",
        "scAccowner",
        "scWebsite",
        "spName",
        "spDescription",
        "spStart",
        "spEnd",
        "spIsnpo",
        "spIsstarted",
        "spFreistellung",
        "spMorefiles",
        "spCostFinancePlan",
        "spAppfromwhere",
        "spAppdate",
        "spAppconfirm",
        "spKtrAo",
        "spFundTotal",
        "spCostTotal",
        "spFundOwn",
        "spAmount",
        "varFields",
        "costDataGrid",
        "fundingDataGrid",
        "scDepartment",
        "scPosition",
        "customTitle",
        "titleChoice",
        "scMobile",
    ];

    const extractPlaceholders = (submissionData) => {
        let submissionDataCopy = Object.assign({}, submissionData);
        const placeholder_keys = [
            "scFirstname",
            "scLastname",
            "scTitle",
            "scSalutation",
            "scSalutationForm",
        ];

        const placeholders = { licenceKundenname: `${licenceKundenname}` };

        placeholder_keys.forEach((key) => {
            placeholders[key] =
                submissionDataCopy[key] === undefined ? "" : submissionDataCopy[key];
        });

        return placeholders;
    };

    /** Function to group all variable fields (vf) into an array 'varFields' */
    const groupVarFields = (submissionData) => {
        let submissionDataCopy = Object.assign({}, submissionData);

        //replace scMobilePhone with scMobile for backward compatibility with old forms
        if (submissionDataCopy["scMobilePhone"]) {
            submissionDataCopy["scMobile"] = submissionDataCopy["scMobilePhone"]; 
            delete submissionDataCopy["scMobilePhone"];
        }

        submissionDataCopy["varFields"] = [];

        let keys = Object.keys(submissionDataCopy);

        keys = keys.filter((x) => !predefined_component_keys.includes(x));

        keys.forEach((key) => {
            if (key == "submit") {
                //ignore submit button
                delete submissionDataCopy[key];
            } else if (key.startsWith("spMorefiles")) {
                if (!submissionDataCopy.spMorefiles) {
                    submissionDataCopy.spMorefiles = [];
                }
                submissionDataCopy.spMorefiles = submissionDataCopy.spMorefiles.concat(
                    submissionDataCopy[key]
                );
                delete submissionDataCopy[key];
            } else {
                submissionDataCopy["varFields"].push({
                    name: key,
                    value: submissionDataCopy[key],
                });

                delete submissionDataCopy[key];
            }
        });

        return submissionDataCopy;
    };

    const extractCostFinanceValues = (submissionData) => {
        const transformedObject = {
            ...submissionData,
        };

        if (submissionData.spCostFinancePlan) {
            transformedObject.spAmount = submissionData.spCostFinancePlan.spAmount;
            transformedObject.spCostTotal =
                submissionData.spCostFinancePlan.spCostTotal;
            transformedObject.spFundOwn = submissionData.spCostFinancePlan.spFundOwn;
            transformedObject.spFundTotal =
                submissionData.spCostFinancePlan.spFundTotal;
        }

        return transformedObject;
    };

    const formatValue = (value, type) => {
        switch (type) {
            case "datetime":
                return formatDate(value);
            case "number":
                return formatNumber(value);
            case "checkbox":
                return value === true ? "Ja" : "Nein";
            case "file":
                const fileNames = value?.map(item => item.originalName);
                return fileNames?.join(", ");
            default:
                //chars like üöä have multiple unicode reprezentation ex. ä single code point \u00E4 or a sequence of two code points \u0061 AND \u0308
                return value?.normalize();
        }

        function formatDate(input) {
            if (input === null || input === undefined) {
                return "";
            }

            let date = new Date(input);

            if (isNaN(date)) {
                return "";
            }

            let dd = String(date.getDate()).padStart(2, "0");
            let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0, hence +1
            let yyyy = date.getFullYear();

            return `${dd}.${mm}.${yyyy}`;
        }

        function formatNumber(input) {
            if (input === null || input === undefined || isNaN(input)) {
                return "";
            }
            return input.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        }
    }

    const handleVarFields = async (submissionData) => {
        return Promise.all([groupVarFields(submissionData)]).catch((error) => {
            return null;
        });
    };

    const handleExtractCostFinanceValues = async (submissionData) => {
        return Promise.all([extractCostFinanceValues(submissionData)]).catch(
            (error) => {
                return null;
            }
        );
    };

    const handleExtractPlaceholders = async (submissionData) => {
        return Promise.all([extractPlaceholders(submissionData)]).catch((error) => {
            return null;
        });
    };

    const handleSubmit = async (submission) => {
        let meta = { applicationType };
        if (foundationNumber && typeof foundationNumber === 'string' && foundationNumber.length > 0) {
            meta = Object.assign(meta, { foundationNumber });
        }

        const dataWithVarFields = await handleVarFields(submission.data);

        const values = (await handleExtractCostFinanceValues(dataWithVarFields[0]))[0];
        const finalJSON = Object.assign({ meta }, values);

        const placeholders_values = await handleExtractPlaceholders(submission.data);

        const lng = i18n.resolvedLanguage;

        //TODO better solution. hack for title component (works as long as no other 'columns' exist in schema)
        function findAndReplaceTitelComponent(jsonObj) {
            function findAndReplace(obj) {
                if (typeof obj === "object" && obj !== null) {
                    if (obj.hasOwnProperty("key") && obj.key === "columns") {
                        // Replace the entire object with the new object
                        obj = {
                            label: "Titel",
                            type: "textfield",
                            key: "scTitle",
                            input: true,
                            validate: {
                                maxLength: 250,
                                required: true,
                            },
                        };
                        return obj;
                    }

                    for (let prop in obj) {
                        if (obj.hasOwnProperty(prop)) {
                            obj[prop] = findAndReplace(obj[prop]);
                        }
                    }
                }
                return obj;
            }

            return findAndReplace(jsonObj);
        }
        findAndReplaceTitelComponent(formSchema);

        function getCostFinanceComponents() {
            let cfComps = [];

            function collectInputs(k) {
                if (typeof k === "object" && k !== null) {
                    if (k.key === "spCostFinancePlan") {
                        cfComps.push(k);
                    } else if (k.hasOwnProperty("components") && Array.isArray(k.components)) {
                        k.components.forEach(subcomponent => {
                            collectInputs(subcomponent);
                        });
                    }
                }
            }
            collectInputs(formSchema);
            return cfComps;
        }

        function getSectionSchemaInputs(section) {
            let esInputs = [];

            function collectInputs(k) {
                if (typeof k === "object" && k !== null && k.key !== "spCostFinancePlan") {
                    if (k.input === true && k.type !== 'button') {
                        esInputs.push(k);
                    } else if (k.hasOwnProperty("components") && Array.isArray(k.components)) {
                        k.components.forEach(subcomponent => {
                            collectInputs(subcomponent);
                        });
                    }
                }
            }
            collectInputs(section);
            
            return esInputs;
        }
    
        function getPdfExportInputValue(schemaInput, submitValue) {
            if (schemaInput.type == "radio") {
                return schemaInput.values.find(k => k.value === submitValue)?.label;
            }

            return formatValue(submitValue, schemaInput.type);
        }


        const doc = new jsPDF();

        formSchema.components.forEach((section, i) => {
            const exportSchemaInputs = getSectionSchemaInputs(section);

            if (exportSchemaInputs && exportSchemaInputs.length > 0) {

                const exportTableRows = exportSchemaInputs.map(k => [
                    k.label, getPdfExportInputValue(k, submission.data[k.key])
                ]);

                doc.autoTable({
                    head: [[{ content: section.title, colSpan: 2, styles: { halign: 'center', fillColor: [128, 128, 128] }}]],
                    showHead: "firstPage",
                    body: exportTableRows,
                    columnStyles: {
                        0: {cellWidth:50, fontStyle:'bold', cellPadding:3},
                        1: {cellWidth:'auto', cellPadding:3},
                    },
                    theme: 'grid',
                    rowPageBreak: 'avoid',
                    //don't use autotable 'pageBreak:avoid' on first section -> this will cause first page to be blank
                    pageBreak: i > 0 ? 'avoid' : 'auto',
                })
            }
        });


        //Kosten und Finanzierung at the end of the pdf in a sepparate table
        const costFinanceSchemaInputs = getCostFinanceComponents();

        if (costFinanceSchemaInputs && costFinanceSchemaInputs.length > 0) {

            //TODO doublecheck, assuming there will be only one spCostFinancePlan component in the form
            const costFinanceSchemaInput = costFinanceSchemaInputs[0];

            const cfpValues = values["spCostFinancePlan"];

            //Kosten table
            const costDataGrid = costFinanceSchemaInput.components.find(k => k.key === "costDataGrid");
            const costTotal = costFinanceSchemaInput.components.find(k => k.key === "spCostTotal");

            let costRows = [];
            cfpValues.costDataGrid.forEach(c => {
                costRows.push([formatValue(c.costType, "string"), formatValue(c.amount, "number")]);
            });
            costRows.push([costTotal.label, formatValue(cfpValues[costTotal.key], "number")]);

            doc.autoTable({
                head: [[{ content: costDataGrid.label, colSpan: 2, styles: { halign: 'center', fillColor: [128, 128, 128] }}]],
                showHead: "firstPage",
                body: costRows,
                columnStyles: {
                    0: {cellWidth:'auto', fontStyle:'bold', cellPadding:3},
                    1: {cellWidth:50, cellPadding:3, halign: 'right'},
                },
                theme: 'grid',
                rowPageBreak: 'avoid',
                pageBreak: 'avoid'
            })

            //Finanzierung table
            const financeDataGrid = costFinanceSchemaInput.components.find(k => k.key === "fundingDataGrid");
            const financeAmount = costFinanceSchemaInput.components.find(k => k.key === "spAmount");
            const financeOwnFunds = costFinanceSchemaInput.components.find(k => k.key === "spFundOwn");            
            const financeTotal = costFinanceSchemaInput.components.find(k => k.key === "spFundTotal");

            let financeRows = [];
            financeRows.push([financeAmount.label, formatValue(cfpValues[financeAmount.key], "number")]);
            financeRows.push([financeOwnFunds.label, formatValue(cfpValues[financeOwnFunds.key], "number")]);
            cfpValues.fundingDataGrid.forEach(f => {
                financeRows.push([formatValue(f.moreFundingType, "string") + " (" + formatValue(f.status, "string") + ")", formatValue(f.moreFundingAmount, "number")]);
            })
            financeRows.push([financeTotal.label, formatValue(cfpValues[financeTotal.key], "number")]);

            doc.autoTable({
                head: [[{ content: financeDataGrid.label, colSpan: 2, styles: { halign: 'center', fillColor: [128, 128, 128] }}]],
                showHead: "firstPage",
                body: financeRows,
                columnStyles: {
                    0: {cellWidth:'auto', fontStyle:'bold', cellPadding:3},
                    1: {cellWidth:50, cellPadding:3, halign: 'right'},
                },
                theme: 'grid',
                rowPageBreak: 'avoid',
                pageBreak: 'avoid'
            })

        }


        const datauri = doc.output("datauristring");
        const substring = datauri.substring(datauri.indexOf(",") + 1);

        if (!finalJSON.spMorefiles) {
            finalJSON.spMorefiles = [];
        }

        finalJSON.spMorefiles.push({
            originalName: "IhreDaten.pdf",
            type: "application/pdf",
            url: datauri.replace("filename=generated.pdf;", "")
        });

        //console.log("finalJSON", finalJSON);
        let finalJSONstr = JSON.stringify(finalJSON);
        let finalJSONB64 = Buffer.from(finalJSONstr).toString("base64");

        UserService.submitApplication(id, customerMailbox, finalJSONB64, lng, licenceKundenname, emailSubject).then(response => {
            console.log(response.message);

            return UserService.sendEmailApplicant(id, applicantEmail, substring, lng, emailSubject, placeholders_values);
        })
        .then((response) => {
            console.log(response.message);
            history.push({
                pathname: `/success`,
                state: {
                    submissionSuccessMessage: submissionSuccessMessage,
                    id: id,
                },
            });
        })
        .catch((error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            console.log("resMessage:", resMessage);
            alert(resMessage);
        });

    };

    useEffect(() => {
        setIsLoggedIn(true);

        UserService.getApplicantForm(id).then(
            (response) => {
                setCustomerMailbox(response.formProps.customer_mailbox);
                setApplicationType(response.formProps.application_type || 'foerderung');
                setFoundationNumber(response.formProps.foundation_number || '');
                setLicenceKundenname(response.licence_Kundenname);
                setFormPaginationColor(response.formProps.form_pagination_color);
                setFormContainerColor(response.formProps.form_container_color);
                setFormPaginationTextColor(
                    response.formProps.form_pagination_text_color
                );
                setFormLogo(response.logo);
                setFormSchema(response.schema);
                setReqLogin(response.req_login);
                setLogoPosition(response.formProps.logo_position);
                setLogoAllign(response.formProps.logo_allign);
                setLogoWidth(response.formProps.logo_width);
                setFormWidth(response.formProps.form_width);
                setBackgroundColor(response.formProps.background_color);
                setTopPadding(response.formProps.top_padding);
                setEmailSubject(response.formProps.email_subject);

                setFormBarColor(response.formProps.form_bar_color);
                setFormBarTextColor(response.formProps.form_bar_text_color);

                if (response.form_messages) {
                    setSubmissionSuccessMessage(
                        response.form_messages.submissionSuccessMessage
                    );
                }

                if (response.schema.display === "form") {
                    const cardBody = document.querySelector(".card-body");
                    const cardHeader = document.querySelector(".card-header");
                    const cardHeaderTitle = document.querySelector(".card-title");

                    const submitButton = document.querySelector(
                        ".btn.btn-primary.btn-md"
                    );

                    cardBody.setAttribute(
                        "style",
                        `background-color: ${response.formProps.form_container_color}`
                    );

                    cardHeader.setAttribute(
                        "style",
                        `background-color: ${response.formProps.form_bar_color} !important`
                    );

                    cardHeaderTitle.setAttribute(
                        "style",
                        `color: ${response.formProps.form_bar_text_color} !important`
                    );

                    submitButton.setAttribute(
                        "style",
                        `background-color: ${response.formProps.form_bar_color}; color: ${response.formProps.form_bar_text_color} ; border-color: ${response.formProps.form_bar_color}; border-radius:4px`
                    );

                    const links = document.getElementsByTagName("a");
                    for (const link of links) {
                        if (
                            link.className === "" ||
                            link.className === "browse" ||
                            link.className === "b4it-link"
                        ) {
                            response.formProps.form_bar_color ===
                                response.formProps.form_container_color
                                ? link.setAttribute("style", "color: blue")
                                : link.setAttribute(
                                    "style",
                                    `color: ${response.formProps.form_bar_color}`
                                );
                        }
                    }

                    const formControls = document.querySelectorAll(".form-control");
                    if (formControls) {
                        formControls.forEach((x) => {
                            x.addEventListener("focus", () => {
                                x.style.boxShadow = `0 0 0 0.2rem ${response.formProps.form_bar_color}`;
                                x.style.borderColor = "#ced4da";
                            });

                            x.addEventListener("blur", () => {
                                x.style.boxShadow = "none";
                                x.style.borderColor = "#ced4da";
                            });
                        });
                    }
                }
            },
            (error) => {
                setReqLogin("unavailable");
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("resMessage:", resMessage);

                if (error.response.data) {
                    setInactiveFormMessage(error.response.data.customMessage);
                    setLogoInactive(error.response.data.logoInactive);
                }
                setErrorMessage(resMessage);
            }
        );
    }, []);

    return errorMessage && errorMessage !== 'Form not active.' ? (
        // Form has an error message
        <Container style={{
            position: 'fixed',
            zIndex: '1',
            top: '0',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            textAlign: 'center',
        }}>
            <Card>
                <Circle style={{ backgroundColor: '#ECE81A' }}>
                    <Exclamation>&#33;</Exclamation>
                </Circle>

                <Message style={{ color: 'black', marginTop: '50px' }}>
                    {errorMessage}
                </Message>
            </Card>

            <FooterContainer>
                Powered by{" "}
                <a href={B4IT_HOMEPAGE} target="_blank" rel="noopener noreferrer" className="b4it-link">base4IT</a>{" "}
                im Auftrag für {licenceKundenname} -{" "}
                <a href={B4IT_IMPRESSUM} target="_blank" rel="noopener noreferrer" className="b4it-link">Impressum</a>{" "}
                -{" "}
                <a href={B4IT_DATA_SECURITY} target="_blank" rel="noopener noreferrer" className="b4it-link">Datenschutzerklärung</a>
            </FooterContainer>
        </Container>
    ) : reqLogin === null ? (
        // Form is loading
        <Container style={{
            position: 'fixed',
            zIndex: '1',
            top: '0',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: '#edf3ea',
        }}>
            <div> Loading ...</div>
        </Container>
    ) : reqLogin === 'false' ? (
        // Form loaded and does not require login
        <Container style={{
            position: 'fixed',
            zIndex: '1',
            top: '0',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: `${backgroundColor}`,
            paddingTop: `${topPadding}`,
        }}>
            <div style={{
                width: window.innerWidth < 768 ? '100%' : `${formWidth}`,
                margin: 'auto',
                minWidth: '300px',
                backgroundColor: `${formContainerColor}`,
                padding: '10px',
                borderRadius: '5px',
            }}>
                {formLogo && logoPosition === "above" && (
                    <LogoContainer style={{ justifyContent: `${logoAllign}` }}>
                        <div style={{ width: `${logoWidth}` }}>
                            <img alt="Not found." style={{ display: 'block', width: '100%', height: 'auto' }} src={formLogo} />
                        </div>
                    </LogoContainer>
                )}
                <Form
                    form={formSchema}
                    onSubmit={(submission) => {
                        const alert = document.querySelector(".alert-success");
                        alert.setAttribute("style", "display:none");

                        if (applicantEmail === null) {
                            setSubmission(submission);
                            if (submission.data["scEmail"] !== null) {
                                setApplicantEmail(submission.data["scEmail"]);
                            }
                            handleShow();
                        } else {
                            handleSubmit(submission);
                        }
                    }}
                    options={{
                        language: i18n.resolvedLanguage,
                        i18n: builderTranslations,
                        languageOverride: {
                            de: {
                                decimalSeparator: ",",
                                delimiter: ".",
                            },
                        },
                    }}
                    onRender={() => {
                        if (formSchema.display === "wizard") {
                            const links = document.getElementsByTagName("a");
                            for (const link of links) {
                                if (
                                    link.className === "" ||
                                    link.className === "browse" ||
                                    link.className === "b4it-link"
                                ) {
                                    formPaginationColor === formContainerColor
                                        ? link.setAttribute("style", "color: blue")
                                        : link.setAttribute(
                                            "style",
                                            `color: ${formPaginationColor}`
                                        );
                                }
                            }
                            const footerButtons = document.querySelectorAll(
                                ".btn-wizard-nav-next, .btn-wizard-nav-cancel, .btn-wizard-nav-previous, .btn-wizard-nav-submit"
                            );

                            footerButtons.forEach((x) => {
                                x.setAttribute(
                                    "style",
                                    `background-color: ${formPaginationColor}; color:${formPaginationTextColor} ; border-color: ${formPaginationColor}; border-radius:4px; box-shadow:none`
                                );
                            });

                            const paginationButtons = document.querySelectorAll(".page-link");
                            paginationButtons.forEach((x) => {
                                x.setAttribute(
                                    "style",
                                    `background-color:${formPaginationColor}; color:${formPaginationTextColor} `
                                );
                            });

                            const formControls = document.querySelectorAll(".form-control");
                            if (formControls) {
                                formControls.forEach((x) => {
                                    x.addEventListener("focus", () => {
                                        x.style.boxShadow = `0 0 0 0.2rem ${formPaginationColor}`;
                                        x.style.borderColor = "#ced4da";
                                    });

                                    x.addEventListener("blur", () => {
                                        x.style.boxShadow = "none";
                                        x.style.borderColor = "#ced4da";
                                    });
                                });
                            }
                        }

                        const costFinance = document.querySelector(
                            ".formio-component-spCostFinancePlan"
                        );

                        if (costFinance) {
                            costFinance.setAttribute("style", "width:850px");
                        }
                    }}
                />

                <Modal show={showModal} dialogClassName="modal-fixed-bottom">
                    <Modal.Header>
                        <Modal.Title>Bitte die E-Mail-Adresse prüfen und bestätigen:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(submission);
                                handleClose();
                            }}
                        >
                            <div className="form-group" style={{ display: "grid" }}>
                                <label className="d-inline-block">
                                    <strong>E-Mail:</strong>
                                </label>
                                <input
                                    type="email"
                                    onChange={(e) => setApplicantEmail(e.target.value)}
                                    value={applicantEmail}
                                    className="form-control lg-1"
                                    style={{
                                        border: "2px solid #ced4da",
                                        boxShadow:
                                            formSchema.display === "form"
                                                ? `0 0 0 0.1rem ${formBarColor}`
                                                : `0 0 0 0.1rem ${formPaginationColor}`,
                                    }}
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary mt-3"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "4px",
                                    backgroundColor:
                                        formSchema.display === "form"
                                            ? formBarColor
                                            : formPaginationColor,
                                    color:
                                        formSchema.display === "form"
                                            ? formBarTextColor
                                            : formPaginationTextColor,
                                    borderColor:
                                        formSchema.display === "form"
                                            ? formBarColor
                                            : formPaginationColor,
                                }}
                            >
                                Absenden
                            </button>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
            <FooterContainer>
                Powered by{" "}
                <a href={B4IT_HOMEPAGE} target="_blank" rel="noopener noreferrer" className="b4it-link">base4IT</a>{" "}
                im Auftrag für {licenceKundenname} -{" "}
                <a href={B4IT_IMPRESSUM} target="_blank" rel="noopener noreferrer" className="b4it-link">Impressum</a>{" "}
                -{" "}
                <a href={B4IT_DATA_SECURITY} target="_blank" rel="noopener noreferrer" className="b4it-link">Datenschutzerklärung</a>
            </FooterContainer>
        </Container>
    ) : reqLogin === 'true' && isLoggedIn === null ? (
        // Form loaded, does require login and is determining login state
        <>
            <div> Loading ...</div>
        </>
    ) : reqLogin === 'true' && isLoggedIn === true ? (
        // Form loaded, requires login and user is logged in
        <Container style={{
            position: 'fixed',
            zIndex: '1',
            top: '0',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: `${backgroundColor}`,
            paddingTop: `${topPadding}`,
        }}>
            <div style={{
                width: window.innerWidth < 768 ? '100%' : `${formWidth}`,
                margin: 'auto',
                minWidth: '300px',
                backgroundColor: `${formContainerColor}`,
                padding: '10px',
                borderRadius: '5px',
            }}>
                {formLogo && logoPosition === "above" && (
                    <LogoContainer style={{ justifyContent: `${logoAllign}` }}>
                        <div style={{ width: `${logoWidth}` }}>
                            <img alt="Not found." style={{ display: "block", width: "100%", height: "auto" }} src={formLogo} />
                        </div>
                    </LogoContainer>
                )}

                <Form
                    form={formSchema}
                    onSubmit={(submission) => {
                        const alert = document.querySelector(".alert-success");
                        alert.setAttribute("style", "display:none");

                        handleSubmit(submission);
                    }}
                    options={{
                        language: i18n.resolvedLanguage,
                        i18n: builderTranslations,
                        languageOverride: {
                            de: {
                                decimalSeparator: ",",
                                delimiter: ".",
                            },
                        },
                    }}
                    onRender={() => {
                        if (formSchema.display === "wizard") {
                            const links = document.getElementsByTagName("a");
                            for (const link of links) {
                                if (
                                    link.className === "" ||
                                    link.className === "browse" ||
                                    link.className === "b4it-link"
                                ) {
                                    formPaginationColor === formContainerColor
                                        ? link.setAttribute("style", "color: blue")
                                        : link.setAttribute(
                                            "style",
                                            `color: ${formPaginationColor}`
                                        );
                                }
                            }
                            const footerButtons = document.querySelectorAll(
                                ".btn-wizard-nav-next, .btn-wizard-nav-cancel, .btn-wizard-nav-previous, .btn-wizard-nav-submit"
                            );

                            footerButtons.forEach((x) => {
                                x.setAttribute(
                                    "style",
                                    `background-color: ${formPaginationColor}; color:${formPaginationTextColor}; border-color: ${formPaginationColor}; border-radius:4px; box-shadow:none`
                                );
                            });

                            const paginationButtons = document.querySelectorAll(".page-link");
                            paginationButtons.forEach((x) => {
                                x.setAttribute(
                                    "style",
                                    `background-color:${formPaginationColor}; color:${formPaginationTextColor} ;`
                                );
                            });

                            const formControls = document.querySelectorAll(".form-control");
                            if (formControls) {
                                formControls.forEach((x) => {
                                    x.addEventListener("focus", () => {
                                        x.style.boxShadow = `0 0 0 0.2rem ${formPaginationColor}`;
                                        x.style.borderColor = "#ced4da";
                                    });

                                    x.addEventListener("blur", () => {
                                        x.style.boxShadow = "none";
                                        x.style.borderColor = "#ced4da";
                                    });
                                });
                            }
                        }

                        const costFinance = document.querySelector(
                            ".formio-component-spCostFinancePlan"
                        );
                        if (costFinance) {
                            costFinance.setAttribute("style", "width:850px");
                        }
                    }}
                />
            </div>
            <FooterContainer>
                Powered by{" "}
                <a href={B4IT_HOMEPAGE} target="_blank" rel="noopener noreferrer" className="b4it-link">base4IT</a>{" "}
                im Auftrag für {licenceKundenname} -{" "}
                <a href={B4IT_IMPRESSUM} target="_blank" rel="noopener noreferrer" className="b4it-link">Impressum</a>{" "}
                -{" "}
                <a href={B4IT_DATA_SECURITY} target="_blank" rel="noopener noreferrer" className="b4it-link">Datenschutzerklärung</a>
            </FooterContainer>
        </Container>
    ) : reqLogin === 'true' && isLoggedIn === false ? (
        // Form loaded, requires login and user is not logged in
        <Container style={{
            position: 'fixed',
            zIndex: '1',
            top: '0',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: '#edf3ea',
        }}>
            <div>
                Please Log in
            </div>
        </Container>
    ) : (
        // Form is not active
        <Container style={{
            position: 'fixed',
            zIndex: '1',
            top: '0',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            textAlign: 'center',
        }}>
            <Card>
                {logoInactive ? (
                    <div style={{ width: '300px', display: 'inline-block' }}>
                        <img alt="Not found." style={{ display: 'block', width: '100%', height: 'auto' }} src={logoInactive} />
                    </div>
                ) : (
                    <Circle style={{ backgroundColor: '#ECE81A' }}>
                        <Exclamation>&#33;</Exclamation>
                    </Circle>
                )}

                <Message style={{ color: 'black', marginTop: '50px', textAlign: 'left' }}>
                    {inactiveFormMessage}
                </Message>
            </Card>

            <FooterContainer>
                Powered by{" "}
                <a href={B4IT_HOMEPAGE} target="_blank" rel="noopener noreferrer" className="b4it-link">base4IT</a>{" "}
                im Auftrag für {licenceKundenname} -{" "}
                <a href={B4IT_IMPRESSUM} target="_blank" rel="noopener noreferrer" className="b4it-link">Impressum</a>{" "}
                -{" "}
                <a href={B4IT_DATA_SECURITY} target="_blank" rel="noopener noreferrer" className="b4it-link">Datenschutzerklärung</a>
            </FooterContainer>
        </Container>
    );
};

export default ApplicantForm;
