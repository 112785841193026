import React, { useState, useEffect } from 'react';
import UserService from '../services/user.service';
import EventBus from '../common/EventBus';
import 'formiojs/dist/formio.full.css';
import TableClient from './tableClient/TableClient';
import { Container } from '../common/styles/PageContainer';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const BoardClient = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const { t } = useTranslation();
    const location = useLocation();

    const clientID = location.state ? location.state.clientID : null;

    useEffect(() => {
        UserService.isClientOrAdmin().then(
            (response) => {
                setIsLoggedIn(true);
            },
            (error) => {
                const errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(errorMessage);

                setIsLoggedIn(false);
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout');
                }
            }
        );
    }, []);

    return isLoggedIn === null ? (
        <>
            <div>Loading ...</div>
        </>
    ) : isLoggedIn === true ? (
        <div style={{ backgroundColor: '#edf3ea', height: '94.75vh' }}>
            <Container>
                <h2>
                    <strong> {t('mainDashboard')} </strong>
                </h2>

                <TableClient propClientID={clientID} />
            </Container>
        </div>
    ) : (
        <div> Please Login</div>
    );
};

export default BoardClient;
