import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { portalTranslations } from './common/translations/portalTranslations';

i18next.use(initReactI18next).use(LanguageDetector).init({
    debug: false,
    fallbackLng: 'de',
    resources: portalTranslations,
});

export default i18next;
