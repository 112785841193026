import api from './api';

/**
 * Returns a 200 status code if the user is an authenticated admin
 */
const isAdmin = () => {
    return api.get('/admin');
};

/**
 * Returns a 200 status code if the user is an authenticated client or admin
 */
const isClientOrAdmin = () => {
    return api.get('/clientOrAdmin');
};

/**
 * Creates a new form
 */
const createForm = (title, logo, schema, status, req_login, emailTemplate, formMessages, formProps, licence_Kundenname, user_email, logoInactive, logoSuccess, isTemplate, client_id, attachmentExtension, emailEncryption, emailPGPPublicKey) => {
    return api.post('/createForm', { title, logo, schema, status, req_login, emailTemplate, formMessages, formProps, licence_Kundenname, user_email, logoInactive, logoSuccess, isTemplate, client_id, attachmentExtension, emailEncryption, emailPGPPublicKey }).then((response) => {
        return response.data;
    });
};

/**
 * Creates a new form as an admin
 */
const createFormViaAdmin = (title, logo, schema, status, req_login, emailTemplate, formMessages, formProps, licence_Kundenname, user_email, logoInactive, logoSuccess, isTemplate, clientID, attachmentExtension) => {
    return api.post("/createFormViaAdmin", { title, logo, schema, status, req_login, emailTemplate, formMessages, formProps, licence_Kundenname, user_email, logoInactive, logoSuccess, isTemplate, clientID, attachmentExtension }).then((response) => {
        return response.data;
    });
};

/**
 * Updates an existing form
 */
const updateForm = (id, formTitle, logo, schema, status, reqLogin, emailTemplate, formMessages, formProps, logoInactive, logoSuccess, attachmentExtension, emailEncryption, emailPGPPublicKey) => {
    return api.put('/updateForm', { id, formTitle, logo, schema, status, reqLogin, emailTemplate, formMessages, formProps, logoInactive, logoSuccess, attachmentExtension, emailEncryption, emailPGPPublicKey });
};

/**
 * Gets all forms for a client
 * @param {number} clientId The client ID
 */
const getAllForms = (clientId) => {
    return api.get('/getAllForms/' + clientId).then(response => {
        return response.data;
    });
};

/**
 * Gets all available form templates
 */
const getTemplates = () => {
    return api.get('/getTemplates').then((response) => {
        return response.data;
    });
};

/**
 * Gets a specific form (for previewing and editing)
 */
const getForm = (id) => {
    return api.get('/getForm/' + id).then((response) => {
        return response.data;
    });
};

/**
 * Get only selected form metadata
 */
const getFormMetadata = (id) => {
    return api.get('/getFormMetadata/' + id).then((response) => {
        return response.data;
    });
};

/**
 * Gets a specific form (for submitting)
 */
const getApplicantForm = (id) => {
    return api.get('/getApplicantForm/' + id).then((response) => {
        return response.data;
    });
};

/**
 * Activates a form
 */
const activateForm = (id) => {
    return api.put('/activateForm', { id }).then(response => {
        return response.data;
    });
};

/**
 * Deactivates a form
 */
const deactivateForm = (id) => {
    return api.put('/deactivateForm', { id }).then(response => {
        return response.data;
    });
};

/**
 * Submits the filled application
 */
const submitApplication = (formId, customerMailbox, finalJSONB64, lng, licenceKundenname, emailSubject) => {
    return api.post('/submitApplication', { formId, customerMailbox, finalJSONB64, lng, licenceKundenname, emailSubject }).then(response => {
        return response.data;
    });
};

/**
 * Sends an email to the submitting applicant
 */
const sendEmailApplicant = (formId, applicantEmail, pdf, lng, emailSubject, placeholders_values) => {
    return api.post('/sendEmailApplicant', { formId, applicantEmail, pdf, lng, emailSubject, placeholders_values }).then(response => {
        return response.data;
    });
};

/**
 * Deletes a form
 */
const deleteForm = (id) => {
    return api.delete('/deleteForm/' + id).then(response => {
        return response.data;
    });
};

/**
 * Gets a list of all client ids and the count of active forms
 */
const activeFormsPerClient = () => {
    return api.get('/activeFormsPerClient').then(response => {
        return response.data;
    });
};

/**
 * Get all of the portal customers
 */
const getPortalCustomers = () => {
    return api.post('/auth/portalCustomers').then(response => {
        return response.data;
    });
};

const UserService = {
    isAdmin,
    isClientOrAdmin,
    createForm,
    createFormViaAdmin,
    updateForm,
    getAllForms,
    getTemplates,
    getForm,
    getFormMetadata,
    getApplicantForm,
    activateForm,
    deactivateForm,
    submitApplication,
    sendEmailApplicant,
    deleteForm,
    activeFormsPerClient,
    getPortalCustomers,
};

export default UserService;
