import React, { useState, useEffect } from 'react';
import UserService from '../services/user.service';
import EventBus from '../common/EventBus';
import FormView from '../components/FormViewer/FormView';
import 'formiojs/dist/formio.full.css';
import { Container } from '../common/styles/PageContainer';

const FormViewPage = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    useEffect(() => {
        UserService.isClientOrAdmin().then(
            (response) => {
                setIsLoggedIn(true);
            },
            (error) => {
                const errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(errorMessage);

                setIsLoggedIn(false);
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout');
                }
            }
        );
    }, []);

    return isLoggedIn === null ? (
        <Container style={{
            position: 'fixed',
            zIndex: '1',
            top: '0',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: '#edf3ea',
        }}>
            <div>Loading ...</div>
        </Container>
    ) : isLoggedIn === true ? (
        <FormView />
    ) : (
        <div>Please Login</div>
    );
};

export default FormViewPage;
