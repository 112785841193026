export const portalTranslations = {
  en: {
    translation: {
      password: "Password:",
      mainDashboard: "My Forms",
      form: "Form",
      dateCreated: "Date Created",
      action: "Action",
      formLink: "Form Link",

      //client dashboard
      edit: "Edit",
      view: "View",
      activate: "Activate",
      deactivate: "Deactivate",
      copy: "Copy",
      delete: "Delete",
      Active: "Active",
      Inactive: "Inactive",
      copyForm: "Copy form",
      enterFormTitle: "Enter form title",
      openNewForm: "Open New Form",

      //form builder
      formDesigner: "Form Designer",
      generalFormSettings: "General Form-Builder Settings",
      formTitle: "Form Title",
      header: "Header",
      aboveForm: "Above Form",
      left: "Left",
      center: "Center",
      right: "Right",
      generalFontSize: "General Font Size",
      small: "small",
      medium: "medium",
      large: "large",
      formDisplay: "Form Display",
      multiPage: "Multi Page",
      singlePage: "Single Page",
      requireLogin: "Require Applicant Log-in",
      yes: "Yes",
      no: "No",
      footer: "Footer",
      apply: "Apply",
      formMessagesTabTitle: "Message texts",
      ifNotActive: "If not activated:",
      ifSuccess: "Successful submission:",
      salutationForm: "Salutation form:",
      emailSubject: "Subject:",
      formSettings: "Form settings",
      emailTemplate: "Email Template",

      logoWidth: "Logo width",
      topPadding: "Top Padding",
      formContainerWidth: "Form container width",
      formPageColour: "Form page colour",
      formContainerColour: "Form Container colour",
      formPaginationColour: "Form pagination colour",
      formPaginationTextColour: "Form pagination text colour",

      formBarColour: "Form bar colour",
      formBarTextColour: "Form bar text colour",

      //warning message
      warningMessageUnsaved:
        "It looks like you have made edits to the form. If you leave before saving, your changes will be lost.",
    },
  },
  de: {
    translation: {
      password: "Passwort:",
      mainDashboard: "Meine Formulare",
      form: "Formular",
      dateCreated: "Erstellt am",
      action: "Aktion",
      formLink: "Formular-Link",

      //client dashboard
      edit: "Bearbeiten",
      view: "Vorschau",
      activate: "Aktivieren",
      deactivate: "Deaktivieren",
      copy: "Kopieren",
      delete: "Löschen",
      Active: "Aktiv",
      Inactive: "Inaktiv",
      copyForm: "Formular kopieren",
      enterFormTitle: "Formularname eingeben",
      openNewForm: "Formular speichern",

      //form builder
      formDesigner: "Formular Designer",
      generalFormSettings: "Allgemeine Einstellungen",
      formTitle: "Formulartitel",
      header: "Kopfzeile",
      aboveForm: "Über dem Formular",
      left: "Links",
      center: "Zentriert",
      right: "Rechts",
      generalFontSize: "Allgemeine Schriftgröße",
      small: "klein",
      medium: "mittel",
      large: "groß",
      formDisplay: "Formulartyp",
      multiPage: "Mehrere Seiten",
      singlePage: "Eine Seite",
      requireLogin: "Anmeldung erforderlich",
      yes: "Ja",
      no: "Nein",
      footer: "Fußzeile",
      apply: "Anwenden",
      formMessagesTabTitle: "Meldungstexte",
      ifNotActive: "Falls nicht aktiviert:",
      ifSuccess: "Erfolgreiche Übertragung:",
      salutationForm: "Anredeform:",
      emailSubject: "Betreff:",
      formSettings: "Formular Einstellungen",
      emailTemplate: "E-Mail Vorlage",

      logoWidth: "Logo Breite",
      topPadding: "Oberer Rand",
      formContainerWidth: "Breite des Formulars",
      formPageColour: "Hintergrundfarbe Seite",
      formContainerColour: "Hintergrundfarbe Formular",
      formPaginationColour: "Button Farbe",
      formPaginationTextColour: "Button Schriftfarbe",
      formBarColour: "Farbe Kopfzeile",
      formBarTextColour: "Textfarbe Kopfzeile",

      //warning message
      warningMessageUnsaved:
        "Sie haben Änderungen vorgenommen. Diese gehen verloren, wenn das Formular nicht gespeichert wird.",
    },
  },
};
