import React, { useState, useEffect } from 'react';
import UserService from '../services/user.service';
import EventBus from '../common/EventBus';
import CustomFormBuilder from '../components/FormBuilder/CustomFormBuilder';
import 'formiojs/dist/formio.full.css';
import { Container } from '../common/styles/PageContainer';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FormBuilder = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        UserService.isClientOrAdmin().then(
            (response) => {
                setIsLoggedIn(true);
            },
            (error) => {
                const errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.error(errorMessage);

                setIsLoggedIn(false);
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout');
                }
            }
        );
    }, []);

    return isLoggedIn === null ? (
        <>
            <div> Loading ... </div>
        </>
    ) : isLoggedIn === true ? (
        <div style={{ backgroundColor: '#edf3ea', minHeight: '100vh' }}>
            <Container>
                <h2>
                    <strong>{t('formDesigner')}</strong>
                </h2>
                {typeof location.state !== 'undefined' ? (
                    <CustomFormBuilder title={location.state.title} />
                ) : (
                    <CustomFormBuilder />
                )}
            </Container>
        </div>
    ) : (
        <div>Please Login</div>
    );
};

export default FormBuilder;
