import React, { useState, useEffect } from "react";
import { Form } from "@formio/react";
import { useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import { Container as LogoContainer } from "../../common/styles/BuilderConfig";
import { Container } from "../../common/styles/PageContainer";
import { builderTranslations } from "../../common/translations/builderTranslations";
import { useTranslation } from "react-i18next";
import { FooterContainer } from "../../common/styles/Footer";

function FormView(props) {
  const { i18n } = useTranslation();
  const [formTitle, setFormTitle] = useState("Form Title");
  const [formSchema, setFormSchema] = useState();
  const [formLogo, setFormLogo] = useState(null);
  const formParams = useParams();

  const [logoPosition, setLogoPosition] = useState("");
  const [logoAllign, setLogoAllign] = useState("");
  const [logoWidth, setLogoWidth] = useState("");
  const [formWidth, setFormWidth] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#edf3ea");
  const [formContainerColor, setFormContainerColor] = useState("#ffffff");
  const [formPaginationColor, setFormPaginationColor] = useState("#ffffff");
  const [formPaginationTextColor, setFormPaginationTextColor] =
    useState("#000000");

  const [topPadding, setTopPadding] = useState("0px");
  const [licenceKundenname, setLicenceKundenname] = useState("");

  const B4IT_HOMEPAGE = process.env.REACT_APP_B4IT_HOMEPAGE;
  const B4IT_IMPRESSUM = process.env.REACT_APP_B4IT_IMPRESSUM;
  const B4IT_DATA_SECURITY = process.env.REACT_APP_B4IT_DATA_SECURITY;

  useEffect(() => {
    UserService.getForm(formParams.id).then(
      (response) => {
        setFormPaginationColor(response.formProps.form_pagination_color);
        setFormContainerColor(response.formProps.form_container_color);
        setFormPaginationTextColor(
          response.formProps.form_pagination_text_color
        );
        setFormSchema(response.schema);
        setFormLogo(response.logo);
        setFormTitle(response.title);
        setLogoPosition(response.formProps.logo_position);
        setLogoAllign(response.formProps.logo_allign);
        setLogoWidth(response.formProps.logo_width);
        setFormWidth(response.formProps.form_width);
        setBackgroundColor(response.formProps.background_color);
        setTopPadding(response.formProps.top_padding);
        setLicenceKundenname(response.licence_Kundenname);

        if (response.schema.display === "form") {
          const cardBody = document.querySelector(".card-body");
          const cardHeader = document.querySelector(".card-header");
          const cardHeaderTitle = document.querySelector(".card-title");

          const submitButton = document.querySelector(
            ".btn.btn-primary.btn-md"
          );

          cardBody.setAttribute(
            "style",
            `background-color: ${response.formProps.form_container_color}`
          );

          cardHeader.setAttribute(
            "style",
            `background-color: ${response.formProps.form_bar_color} !important`
          );

          cardHeaderTitle.setAttribute(
            "style",
            `color: ${response.formProps.form_bar_text_color} !important`
          );

          submitButton.setAttribute(
            "style",
            `background-color: ${response.formProps.form_bar_color}; color: ${response.formProps.form_bar_text_color} ;border-color: ${response.formProps.form_bar_color}; border-radius:4px`
          );

          const links = document.getElementsByTagName("a");
          for (const link of links) {
            if (
              link.className === "" ||
              link.className === "browse" ||
              link.className === "b4it-link"
            ) {
              response.formProps.form_bar_color ===
              response.formProps.form_container_color
                ? link.setAttribute("style", "color: blue")
                : link.setAttribute(
                    "style",
                    `color: ${response.formProps.form_bar_color}`
                  );
            }
          }

          const formControls = document.querySelectorAll(".form-control");
          if (formControls) {
            formControls.forEach((x) => {
              x.addEventListener("focus", () => {
                x.style.boxShadow = `0 0 0 0.2rem ${response.formProps.form_bar_color}`;
                x.style.borderColor = "#ced4da";
              });

              x.addEventListener("blur", () => {
                x.style.boxShadow = "none";
                x.style.borderColor = "#ced4da";
              });
            });
          }
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log("resMessage:", resMessage);
      }
    );
  }, []);

  return (
    <Container
      style={{
        position: "fixed",
        zIndex: "1",
        top: "0",
        overflowX: "hidden",
        width: "100%",
        height: "100%",
        backgroundColor: `${backgroundColor}`,
        paddingTop: `${topPadding}`,
      }}
    >
      <div
        style={{
          width: `${formWidth}`,
          margin: "auto",
          minWidth: "400px",
          backgroundColor: `${formContainerColor}`,
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {formLogo && logoPosition === "above" ? (
          <LogoContainer style={{ justifyContent: `${logoAllign}` }}>
            <div style={{ width: `${logoWidth}` }}>
              <img
                alt="Not found."
                style={{ display: "block", width: "100%", height: "auto" }}
                src={formLogo}
              />
            </div>
          </LogoContainer>
        ) : (
          <div> </div>
        )}
        <Form
          form={formSchema}
          onSubmit={console.log}
          options={{
            language: i18n.resolvedLanguage,
            i18n: builderTranslations,
            languageOverride: {
              de: {
                decimalSeparator: ",",
                delimiter: ".",
              },
            },
          }}
          onRender={() => {
            if (formSchema.display === "wizard") {
              const links = document.getElementsByTagName("a");
              for (const link of links) {
                if (
                  link.className === "" ||
                  link.className === "browse" ||
                  link.className === "b4it-link"
                ) {
                  formPaginationColor === formContainerColor
                    ? link.setAttribute("style", "color: blue")
                    : link.setAttribute(
                        "style",
                        `color: ${formPaginationColor}`
                      );
                }
              }

              const footerButtons = document.querySelectorAll(
                ".btn-wizard-nav-next, .btn-wizard-nav-cancel, .btn-wizard-nav-previous, .btn-wizard-nav-submit"
              );

              footerButtons.forEach((x) => {
                x.setAttribute(
                  "style",
                  `background-color: ${formPaginationColor}; color:${formPaginationTextColor} ;border-color: ${formPaginationColor}; border-radius:4px; box-shadow:none`
                );
              });

              const paginationButtons = document.querySelectorAll(".page-link");
              paginationButtons.forEach((x) => {
                x.setAttribute(
                  "style",
                  `background-color:${formPaginationColor}; color:${formPaginationTextColor}`
                );
              });

              const formControls = document.querySelectorAll(".form-control");
              if (formControls) {
                formControls.forEach((x) => {
                  x.addEventListener("focus", () => {
                    x.style.boxShadow = `0 0 0 0.2rem ${formPaginationColor}`;
                    x.style.borderColor = "#ced4da";
                  });

                  x.addEventListener("blur", () => {
                    x.style.boxShadow = "none";
                    x.style.borderColor = "#ced4da";
                  });
                });
              }
            }

            const costFinance = document.querySelector(
              ".formio-component-spCostFinancePlan"
            );
            if (costFinance) {
              costFinance.setAttribute("style", "width:850px");
            }
          }}
        />
      </div>

      <FooterContainer>
        Powered by{" "}
        <a
          href={B4IT_HOMEPAGE}
          target="_blank"
          rel="noopener noreferrer"
          className="b4it-link"
        >
          base4IT
        </a>{" "}
        im Auftrag für {licenceKundenname} -{" "}
        <a
          href={B4IT_IMPRESSUM}
          target="_blank"
          rel="noopener noreferrer"
          className="b4it-link"
        >
          Impressum
        </a>{" "}
        -{" "}
        <a
          href={B4IT_DATA_SECURITY}
          target="_blank"
          rel="noopener noreferrer"
          className="b4it-link"
        >
          Datenschutzerklärung
        </a>
      </FooterContainer>
    </Container>
  );
}

export default FormView;
