import React, { useState, useEffect } from 'react';
import UserService from '../services/user.service';
import EventBus from '../common/EventBus';
import CustomFormEditor from '../components/FormEditor/CustomFormEditor';
import { Container } from '../common/styles/PageContainer';

const FormEditPage = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(null);

    useEffect(() => {
        UserService.isClientOrAdmin().then(
            (response) => {
                setIsLoggedIn(true);
            },
            (error) => {
                const errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                console.log(errorMessage);

                setIsLoggedIn(false);
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch('logout');
                }
            }
        );
    }, []);

    return isLoggedIn === null ? (
        <>
            <div> Loading ... </div>
        </>
    ) : isLoggedIn === true ? (
        <div style={{ backgroundColor: '#edf3ea', minHeight: '100vh' }}>
            <Container>
                <CustomFormEditor />
            </Container>
        </div>
    ) : (
        <div> Please Login </div>
    );
};

export default FormEditPage;
