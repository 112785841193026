import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30%;
  margin-bottom: 15px;
  // border: 1px solid blue;
  align-items: center;
  justify-content: space-between;
`;

export const Input = styled.input`
  font-size: 1.5em;
  border: 1px solid black;
  border-radius: 3px;
  width: 350px;
`;

export const Select = styled.select`
  font-size: 1em;
  border: 1px solid black;
  border-radius: 3px;

  option {
    color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }

  label {
    font-size: 1em;
  }
`;

export const Button = styled.button`
  font-size: 1em;
  border: 1px solid blue;
  border-radius: 3px;
  background-color: lightblue;
  font-weight: bold;
`;
