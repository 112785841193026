import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { List, ListItem, EditButton, ActionButton } from './styles';
import { Link } from 'react-router-dom';
import UserService from '../../services/user.service';
import AuthService from '../../services/auth.service';
import { useTranslation } from 'react-i18next';
import { MdEdit, MdCheck, MdClose } from 'react-icons/md';
import { IoMdEye, IoMdFlash, IoMdFlashOff, IoMdCopy, IoMdTrash } from 'react-icons/io';
import { Tooltip } from '@mui/material';
import { Modal, ModalFooter } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import eventBus from '../../common/EventBus';

const TableClient = (props) => {
    const { propClientID } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [run, setRun] = useState();
    const [error, setError] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const [showModal2, setShowModal2] = useState(false);
    const handleShow2 = () => setShowModal2(true);
    const handleClose2 = () => setShowModal2(false);

    const [formTitle, setFormTitle] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [formId, setFormId] = useState("123");
    const [deleteId, setDeleteId] = useState("");
    const clientID =
        AuthService.getCurrentUser().roles[0] === "ROLE_ADMIN"
            ? propClientID
            : AuthService.getCurrentUser().clientID;

    if (AuthService.getCurrentUser().roles[0] === "ROLE_ADMIN") {
        localStorage.setItem("externalClientID", propClientID);

        const allCustomers = AuthService.getAllPortalCustomers();

        const client = allCustomers.find((item) => item.clientID === propClientID);

        localStorage.setItem("clientName", client.clientName);

        eventBus.dispatch("updateClientName", { message: client.clientName });
    }

    const [isFormActivating, setIsFormActivating] = useState(false);
    const [isFormDeactivating, setIsFormDeactivating] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        history.push({
            pathname: `/formBuilder/${formId}`,
            state: { title: formTitle, isCopyClient: true },
        });
    };

    const handleActivate = (id) => {
        setIsFormActivating(true);

        UserService.activateForm(id).then(
            (response) => {
                setIsFormActivating(false);
                console.log(response);
                setRun("on");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("resMessage:", resMessage);
                setError(resMessage);
                setIsFormActivating(false);

                if (error.response.status === 401) {
                    alert("You have reached the maximum number of activated forms.");
                }
            }
        );
    };

    const handleDeactivate = (id) => {
        setIsFormDeactivating(true);

        UserService.deactivateForm(id).then(
            (response) => {
                setIsFormDeactivating(false);

                setRun("on");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("resMessage:", resMessage);

                setIsFormDeactivating(false);

                setError(resMessage);
            }
        );
    };

    const handleDelete = (id) => {
        UserService.deleteForm(id).then(
            (response) => {
                console.log(response.message);
                setRun("on");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("resMessage:", resMessage);
                setError(resMessage);
            }
        );
    };

    const columns = [
        {
            field: "title",
            headerName: t("form"),
            minWidth: 350,
            flex: 1,

            renderCell: (params) => {
                return (
                    <>
                        {params.row.is_template_active ? (
                            <ListItem style={{ color: "green" }}>{params.row.title}</ListItem>
                        ) : (
                            <ListItem>{params.row.title}</ListItem>
                        )}
                    </>
                );
            },
        },
        {
            field: "user_email",
            headerName: "Email",
            minWidth: 220,
            flex: 1,

            renderCell: (params) => {
                return <ListItem>{params.row.user_email}</ListItem>;
            },
        },
        {
            field: "status",
            headerName: "Status",
            type: "string",
            minWidth: 80,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.is_template_active ? (
                            <div> Template </div>
                        ) : params.row.status === "Active" ? (
                            <div className="text-primary center">{t(params.row.status)}</div>
                        ) : (
                            <div>{t(params.row.status)}</div>
                        )}
                    </>
                );
            },
        },
        {
            field: "createdAt",
            headerName: t("dateCreated"),
            minWidth: 160,
            flex: 1,
            type: "dateTime",
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: "action",
            headerName: t("action"),
            minWidth: 220,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.is_template_active ? (
                            <ActionButton disabled>
                                <MdEdit />
                            </ActionButton>
                        ) : (
                            <Tooltip title={t("edit")}>
                                {params.row.status === "Inactive" ? (
                                    <ActionButton>
                                        <Link
                                            to={{
                                                pathname: `/formEdit/${params.row.id}`,
                                                state: { isCopyClient: true },
                                            }}
                                        >
                                            <MdEdit color="black" />
                                        </Link>
                                    </ActionButton>
                                ) : (
                                    <ActionButton
                                        onClick={() => alert("Deactivate form in order to edit.")}
                                    >
                                        <MdEdit />
                                    </ActionButton>
                                )}
                            </Tooltip>
                        )}

                        <Tooltip title={t("view")}>
                            <ActionButton>
                                <Link
                                    to={`/formView/${params.row.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoMdEye color="black" size={22} />
                                </Link>
                            </ActionButton>
                        </Tooltip>

                        {params.row.status === "Inactive" ? (
                            <Tooltip title={t("activate")}>
                                <ActionButton
                                    disabled={isFormActivating || params.row.is_template_active}
                                    onClick={() => handleActivate(params.row.id)}
                                >
                                    <IoMdFlash />
                                </ActionButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={t("deactivate")}>
                                <ActionButton
                                    disabled={isFormDeactivating || params.row.is_template_active}
                                    onClick={() => handleDeactivate(params.row.id)}
                                >
                                    <IoMdFlashOff />
                                </ActionButton>
                            </Tooltip>
                        )}

                        <Tooltip title={t("copy")}>
                            <ActionButton
                                onClick={() => {
                                    handleShow();
                                    setFormId(params.row.id);
                                }}
                            >
                                <IoMdCopy />
                            </ActionButton>
                        </Tooltip>

                        <Tooltip title={t("delete")}>
                            {params.row.status === "Inactive" ? (
                                <ActionButton
                                    disabled={params.row.is_template_active}
                                    onClick={() => {
                                        handleShow2();
                                        setDeleteId(params.row.id);
                                        setDeleteTitle(params.row.title);
                                    }}
                                >
                                    <IoMdTrash />
                                </ActionButton>
                            ) : (
                                <ActionButton
                                    disabled={params.row.is_template_active}
                                    onClick={() => alert("Deactivate form in order to delete.")}
                                >
                                    <IoMdTrash />
                                </ActionButton>
                            )}
                        </Tooltip>
                    </>
                );
            },
        },
        {
            field: "link",
            headerName: t("formLink"),
            minWidth: 650,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.status === "Active" && <div>{params.row.link}</div>}
                    </div>
                );
            },
        },
        {
            field: " ",
            headerName: "",
            minWidth: 90,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.status === "Active" && (
                            <div>
                                <EditButton
                                    style={{
                                        backgroundColor: "gray",
                                    }}
                                    className="mx-auto"
                                    onClick={(event) => {
                                        navigator.clipboard.writeText(params.row.link);
                                    }}
                                >
                                    Copy
                                </EditButton>
                            </div>
                        )}
                    </div>
                );
            },
        },
    ];

    const getForms = () => {
        return UserService.getAllForms(clientID).then(
            (response) => {
                return response;
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("resMessage:", resMessage);
            }
        );
    };

    const getTemplates = () => {
        return UserService.getTemplates().then(
            (response) => {
                return response;
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("resMessage:", resMessage);
            }
        );
    };

    useEffect(() => {
        const fetchData = async (run) => {
            setRun("off");
            try {
                const [forms, templates] = await Promise.all([
                    getForms(),
                    getTemplates(),
                ]);
                const mergedData = [...forms, ...templates];
                setData(mergedData);
            } catch (error) {
                console.log("Error fetching data:", error);
            }
        };

        fetchData();
    }, [run]);

    return (
        <>
            <Modal show={showModal} onHide={handleClose} keyboard={true}>
                <Modal.Header>
                    <Modal.Title> {t("copyForm")}</Modal.Title>
                    <button
                        className="btn"
                        style={{ padding: "4px", alignItems: "center" }}
                        onClick={handleClose}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-group" style={{ display: "grid" }}>
                            <label className="d-inline-block">
                                <strong> {t("enterFormTitle")}: </strong>
                            </label>
                            <input
                                type="text"
                                onChange={(e) => setFormTitle(e.target.value)}
                                className="form-control lg-1"
                                style={{ border: "2px solid #67D525" }}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary mt-3"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {" "}
                            <MdCheck />
                            {t("openNewForm")}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showModal2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        Are you sure you want to delete <strong>'{deleteTitle}'</strong> ?{" "}
                    </div>
                </Modal.Body>

                <ModalFooter style={{ justifyContent: "flex-end" }}>
                    <button
                        className="btn btn-secondary mt-3"
                        style={{ borderRadius: "4px" }}
                        onClick={handleClose2}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-danger mt-3"
                        style={{ borderRadius: "4px" }}
                        onClick={() => {
                            handleDelete(deleteId);
                            handleClose2();
                        }}
                    >
                        Delete
                    </button>
                </ModalFooter>
            </Modal>

            <List>
                {/* {error && <div className="text-danger"> Warning: {error} </div>} */}
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "createdAt", sort: "desc" }],
                        },
                    }}
                    autoHeight
                    getRowId={(row) => row.id}
                    rows={data}
                    disableSelectionOnClick
                    columns={columns}
                    hideFooter
                    // pageSize={10}
                    // rowsPerPageOptions={[10]}
                    localeText={{
                        noRowsLabel: "No forms created yet.",
                    }}
                    sx={{
                        background: "white",
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        // "& .MuiDataGrid-cell:focus": {
                        //   outline: "none",
                        // },
                    }}
                />
            </List>
        </>
    );
};

export default TableClient;
