import api from './api';

/**
 * Attempts to sign in via the client and admin endpoint
 * @param {string} email The email of the user
 * @param {string} password The password of the user
 */
const signinClientAndAdmin = (email, password) => {
    return api.post('/auth/signinClientAndAdmin', { email, password }).then((response) => {
        if (response.data.accessToken) {
            AuthService.setCurrentUser(response.data);
        }

        return response.data;
    });
};

/**
 * Logs out the currently authenticated user
 */
const logout = () => {
    AuthService.removeCurrentUser();
    AuthService.removeAllPortalCustomers();
};

/**
 * Gets the current user object from the local storage
 */
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('user'));
};

/**
 * Sets the current user object in the local storage
 */
const setCurrentUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};

/**
 * Removes the current user object from the local storage
 */
const removeCurrentUser = () => {
    localStorage.removeItem('user');
};

/**
 * Gets the current access token from the local storage
 */
const getCurrentAccessToken = () => {
    return AuthService.getCurrentUser()?.accessToken;
};

/**
 * Sets the current access token in the local storage
 */
const setCurrentAccessToken = (token) => {
    let user = AuthService.getCurrentUser();
    user.accessToken = token;

    AuthService.setCurrentUser(user);
};

/**
 * Gets the current access token expiry time from the local storage
 */
const getCurrentAccessTokenExpire = () => {
    return AuthService.getCurrentUser()?.accessTokenExpire;
};

/**
 * Sets the current access token expiry time in the local storage
 */
const setCurrentAccessTokenExpire = (expire) => {
    let user = AuthService.getCurrentUser();
    user.accessTokenExpire = expire;

    AuthService.setCurrentUser(user);
};

/**
 * Gets all portal customers from the local storage
 */
const getAllPortalCustomers = () => {
    const content = localStorage.getItem('allPortalCustomers');
    if (! content) {
        return [];
    }

    const allCustomers = JSON.parse(localStorage.getItem('allPortalCustomers'));

    const allPortalCustomers = allCustomers.filter(customer => customer.maxFormCount !== 0);

    return allPortalCustomers;
};

/**
 * Sets all portal customers in the local storage
 * @param {array} customers The list of portal customers
 */
const setAllPortalCustomers = (customers) => {
    if (customers) {
        localStorage.setItem('allPortalCustomers', JSON.stringify(customers));
    } else {
        localStorage.removeItem('allPortalCustomers');
    }
};

/**
 * Removes all portal customers from the local storage
 */
const removeAllPortalCustomers = () => {
    localStorage.removeItem('allPortalCustomers');
};

const AuthService = {
    signinClientAndAdmin,
    logout,
    getCurrentUser,
    setCurrentUser,
    removeCurrentUser,
    getCurrentAccessToken,
    setCurrentAccessToken,
    getCurrentAccessTokenExpire,
    setCurrentAccessTokenExpire,
    getAllPortalCustomers,
    setAllPortalCustomers,
    removeAllPortalCustomers
};

export default AuthService;
