import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import { MdLogout, MdHome, MdAdd, MdSettings, MdCheck } from 'react-icons/md';
import { IoMdCopy } from 'react-icons/io';
import AuthService from './services/auth.service';
import Login from './pages/Login';
import Home from './pages/Home';
import BoardAdmin from './components/BoardAdmin';
import BoardClient from './components/BoardClient';
import FormBuilder from './pages/FormBuilder';
import FormViewPage from './pages/FormViewPage';
import FormEditPage from './pages/FormEditPage';
import ApplicantForm from './pages/ApplicantForm';
import NotFoundPage from './pages/NotFoundPage';
import SuccessPage from './pages/SuccessPage';
import EventBus from './common/EventBus';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import eventBus from './common/EventBus';

const App = () => {
    const { i18n } = useTranslation();
    const location = useLocation();

    const [currentUser, setCurrentUser] = useState(undefined);

    const [showClientBoard, setShowClientBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    const [licenceKundenname, setLicenceKundenname] = useState("");
    const [clientName, setClientName] = useState("");
    const [updateClientNameFlag, setUpdateClientNameFlag] = useState("off");

    /**
     * Initializes the currently authenticated user and its state.
     */
    useEffect(() => {
        const user = AuthService.getCurrentUser();

        if (user) {
            setCurrentUser(user);

            if (user.roles.includes('ROLE_ADMIN')) {
                setClientName(localStorage.getItem('clientName'));
                setShowAdminBoard(true);
            } else if (user.roles.includes('ROLE_CLIENT')) {
                setLicenceKundenname(user.licence_Kundenname);
                setShowClientBoard(true);
            }
        }

        EventBus.on('logout', () => logOut());
        return () => EventBus.remove('logout');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Sets the flag, that the client name has been updated.
     */
    useEffect(() => {
        eventBus.on('updateClientName', (data) => setUpdateClientNameFlag(data.message));
        return () => eventBus.remove('updateClientName');
    }, []);

    /**
     * Updates the client name after it has been changed (Updates the navigation bar with the client name)
     */
    useEffect(() => {
        if (updateClientNameFlag === 'off') return;
        else setClientName(localStorage.getItem('clientName'));
    }, [updateClientNameFlag]);

    const submitDispatchBuilder = () => EventBus.dispatch('submitDispatchBuilder', { message: 'on' });
    const submitDispatchEditor = () => EventBus.dispatch('submitDispatchEditor', { message: 'on' });
    const submitDispatchEditorCopy = () => EventBus.dispatch('submitDispatchEditorCopy', { message: 'on' });

    /**
     * Logs out the currently authenticated user and redirects to the login page
     */
    const logOut = () => {
        AuthService.logout();
        setCurrentUser(undefined);

        setShowClientBoard(false);
        setShowAdminBoard(false);
        window.location.href = '/';
    };

    /**
     * Checks whether the currently authenticated admin is viewing a clients board.
     * Results in creating a new form for the client when clicking on the plus symbol
     */
    const showAdminBoardForClient = () => showAdminBoard && location.pathname.includes('/client');

    /**
     * Checks whether the currently authenticated admin is viewing the admin board.
     * Results in creating a new template when clicking on the plus symbol
     */
    const showAdminBoardForAdmin = () => showAdminBoard && ! location.pathname.includes('/client');

    return (
        <div style={{ backgroundColor: '#edf3ea', height: '100vh !important' }}>

            {/* Navigation Bar */}
            <nav className="navbar navbar-expand navbar-light" style={{ backgroundColor: '#67D525', height: '45px' }}>
                <Link to={'/'} className="navbar-brand">
                    <img src={require('./common/img/b4IT-logo-2.png')} width="90" alt="b4IT-logo" height="35"></img>
                </Link>

                {/* Left Side */}
                <div className="navbar-nav mr-auto">

                    {/* Home Button */}
                    {(showClientBoard || showAdminBoard) && (
                        <li className="nav-item active">
                            <Link to={showClientBoard ? '/client' : '/admin' } className="nav-link">
                                <MdHome size={24} />
                            </Link>
                        </li>
                    )}

                    {/* Add Form Button */}
                    {(showClientBoard || showAdminBoardForClient || showAdminBoardForAdmin) && (
                        <li className="nav-item active">
                            <Link to={showAdminBoardForAdmin ? '/formBuilder/t' : '/formBuilder/n'} className="nav-link">
                                <MdAdd size={24} />
                            </Link>
                        </li>
                    )}

                    {/* Create Form Button */}
                    {(showClientBoard || showAdminBoard) && location.pathname.includes('/formBuilder') && (
                        <li className="nav-item">
                            <button className="btn" style={{ padding: '8px', alignItems: 'center' }} onClick={submitDispatchBuilder}>
                                <MdCheck size={24} />
                            </button>
                        </li>
                    )}

                    {/* Copy And Save Form Button */}
                    {(showClientBoard || showAdminBoard) && location.pathname.includes('/formEdit') && (
                        <>
                            <li className="nav-item">
                                <button className="btn" style={{ padding: '8px', alignItems: 'center' }} onClick={submitDispatchEditorCopy}>
                                    <IoMdCopy size={24} />
                                </button>
                            </li>
                            <li className="nav-item">
                                <button className="btn" style={{ padding: '8px', alignItems: 'center' }} onClick={submitDispatchEditor}>
                                    <MdCheck size={24} />
                                </button>
                            </li>
                        </>
                    )}
                </div>

                {/* Customer Name */}
                {showClientBoard && licenceKundenname !== null && (
                    <div className="nav-item">
                        <strong style={{ paddingRight: '100px' }}>
                            {licenceKundenname}
                        </strong>
                    </div>
                )}

                {/* Client Name */}
                {showAdminBoard && clientName !== null && location.pathname.includes('/client') && (
                    <div>
                        <strong style={{ paddingRight: '100px' }}>
                            {clientName}
                        </strong>
                    </div>
                )}

                {/* Right Side */}
                <div className="navbar-nav ml-auto">
                    {currentUser && (
                        <>
                            {/* Settings */}
                            <li className="nav-item dropdown">
                                <a id="navbarDropdownMenuLink" href="#/" className="btn dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <MdSettings size={24} />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                                    <form>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                &#127760; Language: {i18n.resolvedLanguage === 'de' ? 'Deutsch' : 'English'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ margin: 0 }}>
                                                <Dropdown.Item onClick={() => i18n.changeLanguage('de')}>Deutsch</Dropdown.Item>
                                                <Dropdown.Item onClick={() => i18n.changeLanguage('en')}>English</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </form>
                                </div>
                            </li>

                            <li className="nav-item">
                                <a href={'/#'} className="nav-link" onClick={logOut}>
                                    <MdLogout size={24} />
                                </a>
                            </li>
                        </>
                    )}
                </div>
            </nav>

            <div>
                <Switch>
                    <Route exact path={["/login", "/"]} component={Login} />
                    <Route exact path={"/home"} component={Home} />
                    <Route path="/admin" component={BoardAdmin} />
                    <Route path="/client" component={BoardClient} />
                    <Route path="/formBuilder/:type" component={FormBuilder} />
                    <Route path="/formView/:id" component={FormViewPage} />
                    <Route path="/formEdit/:id" component={FormEditPage} />
                    <Route path="/applicantForm/:id" component={ApplicantForm} />
                    <Route path="/success" component={SuccessPage} />
                    <Route component={NotFoundPage} />
                </Switch>
            </div>
        </div>
    );
};

export default App;
