import React, { useState, useEffect } from 'react';
import { FooterContainer } from '../common/styles/Footer';
import { Container } from '../common/styles/PageContainer';
import { Card, Circle, Checkmark, Message } from '../common/styles/SuccessPageStyles';
import { useLocation } from 'react-router-dom';
import UserService from '../services/user.service';

const B4IT_HOMEPAGE = process.env.REACT_APP_B4IT_HOMEPAGE;
const B4IT_IMPRESSUM = process.env.REACT_APP_B4IT_IMPRESSUM;
const B4IT_DATA_SECURITY = process.env.REACT_APP_B4IT_DATA_SECURITY;

const SuccessPage = () => {
    const location = useLocation();
    const submissionSuccessMessage = location.state.submissionSuccessMessage;
    const formId = location.state.id;
    const [logoSuccess, setLogoSuccess] = useState(null);

    useEffect(() => {
        UserService.getApplicantForm(formId).then(
            (response) => {
                if (response.logo_success) {
                    setLogoSuccess(response.logo_success);
                }
            },
            (error) => {
                const errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();

                console.erro(errorMessage);
            }
        );
    }, []);

    return (
        <Container style={{
            position: 'fixed',
            zIndex: '1',
            top: '0',
            overflowX: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            textAlign: 'center',
        }}>
            <Card>
                {logoSuccess ? (
                    <div style={{ width: '300px', display: 'inline-block' }}>
                        <img alt="Not found." style={{ display: 'block', width: '100%', height: 'auto' }} src={logoSuccess} />
                    </div>
                ) : (
                    <Circle>
                        <Checkmark className="checkmark">&#10003;</Checkmark>
                    </Circle>
                )}

                <Message style={{ marginTop: '50px' }}>
                    {submissionSuccessMessage}
                </Message>
            </Card>

            <FooterContainer>
                Powered by{" "}
                <a href={B4IT_HOMEPAGE} target="_blank" rel="noopener noreferrer">base4IT</a>{" "}
                -{" "}
                <a href={B4IT_IMPRESSUM} target="_blank" rel="noopener noreferrer">Impressum</a>{" "}
                -{" "}
                <a href={B4IT_DATA_SECURITY} target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>
            </FooterContainer>
        </Container>
    );
};

export default SuccessPage;
