import styled from "styled-components";

export const List = styled.div`
  display: "flex";
  // padding: 20px;
  height: 85vh;
  background-color: #edf3ea;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const EditButton = styled.button`
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: ${props => (props.primary ? "blue" : "green")};
  color: white;
  cursor: pointer;
  margin-right: 20px;
`;
