import styled from "styled-components";

export const Card = styled.div`
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  margin-top: 100px;
`;

export const Circle = styled.div`
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #f8faf5;
  margin: 0 auto;
`;

export const Checkmark = styled.i`
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
`;

export const Exclamation = styled.span`
  color: black;
  font-size: 150px;
  line-height: 200px;
`;

export const Header = styled.h1`
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
`;

export const Message = styled.pre`
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  line-height: normal;
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
