import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import { Container } from "../common/styles/PageContainer";
import TableAllUsers from "./tableAllUsers/TableAllUsers";
import TableTemplates from "./tableTemplates/TableTemplates";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const BoardAdmin = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    UserService.isAdmin().then(
      (response) => {
        setIsLoggedIn(true);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(_content);

        setIsLoggedIn(false);

        if (error.response && error.response.status === 403) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return isLoggedIn === null ? (
    <>
      <div> Loading ... </div>
    </>
  ) : isLoggedIn === true ? (
    <div>
      <Container>
        <Tabs
          defaultActiveKey={"template-tab"}
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey={"template-tab"} title="Templates">
            <TableTemplates />
          </Tab>

          <Tab eventKey={"customer-tab"} title="Customers">
            <TableAllUsers />
          </Tab>
        </Tabs>
      </Container>
    </div>
  ) : (
    <div>Please Login</div>
  );
};

export default BoardAdmin;
