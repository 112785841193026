import axios from 'axios';
import AuthService from './auth.service';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.request.use(
    (config) => {
        const token = AuthService.getCurrentAccessToken();
        if (token) {
            config.headers['x-access-token'] = token;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {

        //
        // Update refreshed access tokens
        if (res.headers.hasOwnProperty('x-updated-access-token') && res.headers.hasOwnProperty('x-updated-access-token-expire')) {
            AuthService.setCurrentAccessToken(res.headers['x-updated-access-token']);
            AuthService.setCurrentAccessTokenExpire(res.headers['x-updated-access-token-expire']);
        }

        return res;
    },
    async (err) => {
        return Promise.reject(err);
    }
);

export default instance;
