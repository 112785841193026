import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
    List,
    ListItem,
    ActionButton,
} from "../tableClient/styles";
import { Link } from "react-router-dom";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { useTranslation } from "react-i18next";
import { MdEdit, MdCheck, MdClose } from "react-icons/md";
import {
    IoMdEye,
    IoMdFlash,
    IoMdFlashOff,
    IoMdCopy,
    IoMdTrash,
} from "react-icons/io";
import { Tooltip } from "@mui/material";
import { Modal, ModalFooter } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const TableTemplates = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState([]);
    const [run, setRun] = useState();
    const [error, setError] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const [showModal2, setShowModal2] = useState(false);
    const handleShow2 = () => setShowModal2(true);
    const handleClose2 = () => setShowModal2(false);

    const [formTitle, setFormTitle] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [formId, setFormId] = useState("123");
    const [deleteId, setDeleteId] = useState("");
    const clientID = AuthService.getCurrentUser().clientID;

    const [isFormActivating, setIsFormActivating] = useState(false);
    const [isFormDeactivating, setIsFormDeactivating] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault();

        history.push({
            pathname: `/formBuilder/${formId}`,
            state: { title: formTitle },
        });
    };

    const handleActivate = (id) => {
        setIsFormActivating(true);

        UserService.activateForm(id).then(
            (response) => {
                setIsFormActivating(false);

                setRun("on");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log("resMessage:", resMessage);
                setError(resMessage);
                setIsFormActivating(false);

                if (error.response.status === 401) {
                    alert("You have reached the maximum number of activated forms.");
                }
            }
        );
    };

    const handleDeactivate = (id) => {
        setIsFormDeactivating(true);

        UserService.deactivateForm(id).then(
            (response) => {
                setIsFormDeactivating(false);

                setRun("on");
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setIsFormDeactivating(false);

                setError(resMessage);
            }
        );
    };

    const handleDelete = (id) => {
        UserService.deleteForm(id).then(response => {
            setRun("on");
        }, error => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            setError(resMessage);
        });
    };

    const columns = [
        {
            field: "title",
            headerName: "Form template",
            minWidth: 350,
            flex: 1,

            renderCell: (params) => {
                return <ListItem>{params.row.title}</ListItem>;
            },
        },
        {
            field: "user_email",
            headerName: "Email",
            minWidth: 220,
            flex: 1,

            renderCell: (params) => {
                return <ListItem>{params.row.user_email}</ListItem>;
            },
        },
        {
            field: "is_template_active",
            headerName: "Status",
            type: "string",
            minWidth: 80,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {" "}
                        {params.row.is_template_active ? (
                            <div className="text-primary center">Active</div>
                        ) : (
                            <div>Inactive</div>
                        )}{" "}
                    </>
                );
            },
        },
        {
            field: "createdAt",
            headerName: t("dateCreated"),
            minWidth: 160,
            flex: 1,
            type: "dateTime",
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: "action",
            headerName: t("action"),
            minWidth: 220,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title={t("edit")}>
                            {!params.row.is_template_active ? (
                                <ActionButton>
                                    <Link to={`/formEdit/${params.row.id}`}>
                                        <MdEdit color="black" />
                                    </Link>
                                </ActionButton>
                            ) : (
                                <ActionButton
                                    onClick={() =>
                                        alert("Deactivate form template in order to edit.")
                                    }
                                >
                                    <MdEdit />
                                </ActionButton>
                            )}
                        </Tooltip>

                        <Tooltip title={t("view")}>
                            <ActionButton>
                                <Link
                                    to={`/formView/${params.row.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IoMdEye color="black" size={22} />
                                </Link>
                            </ActionButton>
                        </Tooltip>

                        {params.row.is_template_active ? (
                            <Tooltip title={t("deactivate")}>
                                <ActionButton
                                    disabled={isFormDeactivating}
                                    onClick={() => handleDeactivate(params.row.id)}
                                >
                                    <IoMdFlashOff />
                                </ActionButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={t("activate")}>
                                <ActionButton
                                    disabled={isFormActivating}
                                    onClick={() => handleActivate(params.row.id)}
                                >
                                    <IoMdFlash />
                                </ActionButton>
                            </Tooltip>
                        )}

                        <Tooltip title={t("copy")}>
                            <ActionButton
                                onClick={() => {
                                    handleShow();
                                    setFormId(params.row.id);
                                }}
                            >
                                <IoMdCopy />
                            </ActionButton>
                        </Tooltip>

                        <Tooltip title={t("delete")}>
                            <ActionButton
                                onClick={() => {
                                    handleShow2();
                                    setDeleteId(params.row.id);
                                    setDeleteTitle(params.row.title);
                                }}
                            >
                                <IoMdTrash />
                            </ActionButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    useEffect(
        (run) => {
            setRun("off");

            UserService.getAllForms(clientID).then(
                (response) => {
                    setData(response);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    console.log("resMessage:", resMessage);
                }
            );
        },
        [run]
    );

    return (
        <>
            <Modal show={showModal} onHide={handleClose} keyboard={true}>
                <Modal.Header>
                    <Modal.Title> {t("copyForm")}</Modal.Title>
                    <button
                        className="btn"
                        style={{ padding: "4px", alignItems: "center" }}
                        onClick={handleClose}
                    >
                        <MdClose />
                    </button>
                </Modal.Header>

                <Modal.Body>
                    <form onSubmit={handleFormSubmit}>
                        <div className="form-group" style={{ display: "grid" }}>
                            <label className="d-inline-block">
                                <strong> {t("enterFormTitle")}: </strong>
                            </label>
                            <input
                                type="text"
                                onChange={(e) => setFormTitle(e.target.value)}
                                className="form-control lg-1"
                                style={{ border: "2px solid #67D525" }}
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className="btn btn-primary mt-3"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "4px",
                            }}
                        >
                            {" "}
                            <MdCheck />
                            {t("openNewForm")}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showModal2}
                onHide={handleClose2}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        Are you sure you want to delete <strong>'{deleteTitle}'</strong> ?{" "}
                    </div>
                </Modal.Body>

                <ModalFooter style={{ justifyContent: "flex-end" }}>
                    <button
                        className="btn btn-secondary mt-3"
                        style={{ borderRadius: "4px" }}
                        onClick={handleClose2}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-danger mt-3"
                        style={{ borderRadius: "4px" }}
                        onClick={() => {
                            handleDelete(deleteId);
                            handleClose2();
                        }}
                    >
                        Delete
                    </button>
                </ModalFooter>
            </Modal>

            <List>
                {/* {error && <div className="text-danger"> Warning: {error} </div>} */}
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "createdAt", sort: "desc" }],
                        },
                    }}
                    autoHeight
                    getRowId={(row) => row.id}
                    rows={data}
                    disableSelectionOnClick
                    columns={columns}
                    hideFooter
                    // pageSize={10}
                    // rowsPerPageOptions={[10]}
                    localeText={{
                        noRowsLabel: "No form templates created yet.",
                    }}
                    sx={{
                        background: "white",
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                    }}
                />
            </List>
        </>
    );
};

export default TableTemplates;
